* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  background-color: #2e2e2e;
}

body {
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button:before {
  color: #ffffff !important;
  font-size: 8px !important;
}

.slick-dots li.slick-active button:before {
  color: #ff7c06 !important;
  font-size: 12px !important;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  border-radius: 25px !important;
}

.slick-slide img {
  width: 100% !important;
  height: 100% !important;
  max-height: 650px !important;
  object-fit: fill !important;
  object-position: center !important;
  border-radius: 25px !important;
}

@media (max-width: 1850px) {
  .slick-slide img {
    max-height: 550px !important;
  }
}

@media (max-width: 1550px) {
  .slick-slide img {
    max-height: 450px !important;
  }
}

@media (max-width: 1200px) {
  .slick-slide img {
    max-height: 350px !important;
  }
}

@media (max-width: 960px) {
  .slick-slide img {
    max-height: 285px !important;
  }
}

@media (max-width: 768px) {
  .slick-slide img {
    max-height: 685px !important;
  }
}

@media (max-width: 600px) {
  .slick-slide img {
    max-height: 685px !important;
  }
}

@media (max-width: 480px) {
  .slick-slide img {
    max-height: 100% !important;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
